// App - Types
import type { Significance } from '../significance';

export const SignificancesDto = ['Major', 'Notable', 'Minor', 'All'] as const;

export type SignificanceDto = (typeof SignificancesDto)[number];

export const castSignificanceDtoToSignificance = (dto: SignificanceDto): Significance => {
  if (dto === 'Major') return 'major';

  if (dto === 'Notable') return 'notable';

  if (dto === 'Minor') return 'minor';

  return 'UNKNOWN';
};

export const castSignificanceDtoToScore = (dto: SignificanceDto): number => {
  if (dto === 'Minor') return 3;

  if (dto === 'Notable') return 6;

  if (dto === 'Major') return 10;

  return -1;
};

export const castSignificanceToSignificanceDto = (significance: Significance): SignificanceDto => {
  if (significance === 'major') return 'Major';

  if (significance === 'notable') return 'Notable';

  if (significance === 'minor') return 'Minor';

  return 'All';
};

export const castScoreToSignificanceDto = (score: number): SignificanceDto => {
  if (!score || score === -1) return 'All';

  if (score <= 3) return 'Minor';

  if (score <= 6) return 'Notable';

  return 'Major';
};

export const castScoreToSignificance = (score: number): Significance => {
  if (!score || score === -1) return 'UNKNOWN';

  if (score <= 3) return 'minor';

  if (score <= 6) return 'notable';

  return 'major';
};
