/**
 * This component is heavily influenced by the MRT Table Library
 * Original Component Name: MRT_TableBodyCellValue
 * Source Code: (https://github.com/KevinVandy/material-react-table/blob/c1c6dace1f685e1d1e1c3e0a3e25940edfe49a63/packages/material-react-table/src/body/MRT_TableBodyCellValue.tsx#L18)
 */

// React & Next
import { type ReactNode } from 'react';

// 3rd
import highlightWords from 'highlight-words';
import { Box, useColorMode } from '@chakra-ui/react';

// App
import { getTableTheme } from '../../style.utils';
import { type MRT_Cell, type MRT_RowData, type MRT_TableInstance } from '../../types';

const allowedTypes = ['string', 'number'];

interface Props<TData extends MRT_RowData> {
  cell: MRT_Cell<TData>;
  table: MRT_TableInstance<TData>;
}

export const TableBodyCellValue = <TData extends MRT_RowData>({ cell, table }: Props<TData>) => {
  const colorModeContext = useColorMode();
  const {
    getState,
    options: { enableFilterMatchHighlighting },
  } = table;
  const { column, row } = cell;
  const { columnDef } = column;
  const { globalFilter, globalFilterFn } = getState();
  const filterValue = column.getFilterValue();

  let renderedCellValue =
    cell.getIsAggregated() && columnDef.AggregatedCell
      ? columnDef.AggregatedCell({
          cell,
          column,
          row,
          table,
        })
      : row.getIsGrouped() && !cell.getIsGrouped()
        ? null
        : cell.getIsGrouped() && columnDef.GroupedCell
          ? columnDef.GroupedCell({
              cell,
              column,
              row,
              table,
            })
          : undefined;

  const isGroupedValue = renderedCellValue !== undefined;

  if (!isGroupedValue) {
    renderedCellValue = cell.renderValue() as ReactNode | number | string;
  }

  if (
    enableFilterMatchHighlighting &&
    columnDef.enableFilterMatchHighlighting !== false &&
    String(renderedCellValue) &&
    allowedTypes.includes(typeof renderedCellValue) &&
    ((filterValue &&
      allowedTypes.includes(typeof filterValue) &&
      ['autocomplete', 'text'].includes(columnDef.filterVariant!)) ||
      (globalFilter && allowedTypes.includes(typeof globalFilter) && column.getCanGlobalFilter()))
  ) {
    const chunks = highlightWords?.({
      matchExactly: (filterValue ? columnDef._filterFn : globalFilterFn) !== 'fuzzy',
      query: (filterValue ?? globalFilter ?? '').toString(),
      text: renderedCellValue?.toString() as string,
    });

    if (chunks?.length > 1 || chunks?.[0]?.match) {
      renderedCellValue = (
        <span aria-label={renderedCellValue as string} role="note">
          {chunks?.map(({ key, match, text }) => (
            <Box
              aria-hidden="true"
              as="span"
              key={key}
              sx={
                match
                  ? {
                      backgroundColor: getTableTheme(table, colorModeContext).matchHighlightColor,
                      borderRadius: '2px',
                      // color: colorModeContext.colorMode === 'dark' ? '#fff' : '#1F2328',
                      color: 'text.primary',
                      padding: '2px 1px',
                    }
                  : undefined
              }
            >
              {text}
            </Box>
          )) ?? renderedCellValue}
        </span>
      );
    }
  }

  if (columnDef.Cell && !isGroupedValue) {
    renderedCellValue = columnDef.Cell({
      cell,
      column,
      renderedCellValue,
      row,
      table,
    });
  }

  return renderedCellValue;
};
